<style lang="less" scoped>
.info-card {
  height: 80px;
  color: #fff;
  line-height: 80px;
}
.info-card-title {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-card-value {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataTable {
  width: 100%;
  th,
  td {
    height: 40px;
    border: 1px solid rgb(230, 230, 230);
    padding: 10px 15px;
  }
}
.dataTable-right-title {
  color: #fff;
  font-weight: bold;
  width: 2%;
  word-wrap: break-word;
}
</style>
<template>
  <Modal v-model="showCostBillDetail" width="1000">
    <Row :gutter="8">
      <i-col span="6">
        <Select size="small" v-model="supplierCompanyId">
          <Option :value="item.id" v-for="item in companyArray" :key="item.id">{{item.name}}</Option>
        </Select>
      </i-col>
    </Row>

    <p slot="header">成本额明细</p>
    <table class="dataTable m-t-10" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <template v-for="(item,index) in costList">
          <tr :key="'costBill_'+index">
            <td>{{item.feeTypeName}}</td>
            <td class="text-right">{{htmlToMoney(item.executedAmount)}}</td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" style="text-align:right;font-size:22px;font-weight:bold">
            <span>总计：</span>
            <span style="color:red">{{htmlToMoney(totalCost)}}</span>
          </td>
        </tr>
      </tfoot>
    </table>

    <div slot="footer">
        <Button type="text"  @click="showCostBillDetail=false">取消</Button>
        <Button type="primary"  @click="handleDownload">确认</Button>
      </div>

  </Modal>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getCompanyList } from '@/api/os/company'
import { getAgentBillList } from '@/api/statement/agentsupplierbill'
import { downloadFileRequest } from '@/utils/download'
export default {
  data () {
    return {
      showCostBillDetail: false,
      companyArray: [],
      supplierCompanyId: 0,
      costList: [],
      tableColumns: [],
      totalCost: 0
    }
  },
  methods: {
    showModal () {
      this.showCostBillDetail = true
      this.initCompanys()
    },
    // 加载供应商公司列表
    initCompanys () {
      this.companyArray = []
      const postData = {
        publisherId: this.publisherId,
        enabled: true,
        type: 3
      }
      getCompanyList(postData).then((res) => {
        if (res && !res.errcode) {
          this.companyArray = res
          this.supplierCompanyId = res[0].id
        }
      })
    },
    initCostBill () {
      const query = {
        supplierCompanyId: this.supplierCompanyId,
        publisherId: this.publisherId,
        agentCompanyId: this.companyId,
        startDate: this.startDate,
        endDate: this.endDate
      }
      getAgentBillList(query).then((res) => {
        this.costList = res.feeTypeBillList
        this.totalCost = res.executedAmount
      })
    },
    htmlToMoney (obj) {
      return toMoney(obj)
    },
    handleDownload () {
      const query = {
        companyId: this.companyId,
        publisherId: this.publisherId,
        startDate: this.startDate,
        endDate: this.endDate,
        supplierId: this.supplierCompanyId
      }
      const filename = `成本额明细（${this.startDate}至${this.endDate}）.xls`
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v1/dw/verify/exportMaintainAmountDetail', query, filename)
    }
  },
  computed: {
    companyId () {
      return this.$store.state.reconciliation.companyId
    },
    publisherId () {
      return this.$store.state.reconciliation.publisherId
    },
    startDate () {
      return this.$store.state.reconciliation.startDate
    },
    endDate () {
      return this.$store.state.reconciliation.endDate
    },
    serviceType () {
      return this.$store.state.reconciliation.serviceType
    }
  },
  watch: {
    supplierCompanyId (val) {
      this.initCostBill()
    }
  }
}
</script>
