<style lang="less" scoped>
.info-card {
  height: 80px;
  color: #fff;
  line-height: 80px;
}
.info-card-title {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-card-value {
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataTable {
  width: 100%;
  th,
  td {
    height: 40px;
    border: 1px solid rgb(230, 230, 230);
    padding: 10px 15px;
  }
}
.dataTable-right-title {
  color: #fff;
  font-weight: bold;
  width: 2%;
  word-wrap: break-word;
}
</style>

<template>
  <div>
    <Row class="m-b-10">
      <i-col span="12">
        <h2>{{feeInfo.companyName}} {{feeInfo.name}}对账簿</h2>
      </i-col>
      <i-col span="12" class="text-right">
        <template v-if="serviceType===1">
          <Button
            type="primary"
            class="m-r-5"
            v-if="feeInfo.status===2"
            @click="handleLaunch"
          >确认发起对账</Button>
          <Button
            type="error"
            class="m-r-5"
            v-if="feeInfo.status===3"
            @click="handleRecall"
          >撤回发起对账</Button>
          <!-- <Button
          type="success"
          @click="handleAdjustment"
          >发起调整</Button>-->
        </template>
        <template v-else>
          <Button type="primary" class="m-r-5" v-if="feeInfo.status===3" @click="handleLaunch">确认对账</Button>
          <Button type="error" class="m-r-5" v-if="feeInfo.status===3" @click="handleRecall">拒绝对账</Button>
        </template>
      </i-col>
    </Row>
    <Row :gutter="16" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="8">
        <Card style="background-color:#2b85e4">
          <Row class="info-card">
            <i-col span="10" class="info-card-title">季度指标</i-col>
            <i-col span="14" class="info-card-value">{{htmlToMoney(feeInfo.planAmount)}}</i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :xs="24" :sm="12" :md="8">
        <Card style="background-color:#2d8cf0">
          <Row class="info-card">
            <i-col span="10" class="info-card-title">权益额</i-col>
            <i-col span="14" class="info-card-value">{{htmlToMoney(feeInfo.executedAmount)}}</i-col>
          </Row>
        </Card>
      </i-col>
      <i-col :xs="24" :sm="12" :md="8">
        <Card style="background-color:#5cadff">
          <Row class="info-card">
            <i-col span="10" class="info-card-title">完成比</i-col>
            <i-col span="14" class="info-card-value">{{(feeInfo.finishRatio*100).toFixed(2)}}%</i-col>
          </Row>
        </Card>
      </i-col>
    </Row>
    <div class="m-b-10">
      <table class="dataTable" border="0" cellspacing="0" cellpadding="0">
        <!-- <thead style="text-align:left">
          <tr>
            <th colspan="3"></th>
            <th>真实发生金额</th>
            <th>取费方式</th>
            <th>取费金额</th>
          </tr>
        </thead>-->
        <tbody>
          <tr>
            <td
              :rowspan="feeBill.feeTypeList.length"
              class="dataTable-right-title"
              style="background-color:#2d8cf0;font-size:16px;"
            >权益额列表</td>
            <td>{{feeBill.feeTypeList.length>0?feeBill.feeTypeList[0].feeTypeName:''}}</td>
            <td
              class="text-right"
            >{{feeBill.feeTypeList.length>0?htmlToMoney(feeBill.feeTypeList[0].executedAmount):''}}</td>
          </tr>
          <template v-for="(item,index) in feeBill.feeTypeList">
            <tr :key="'costBill_'+index" v-if="index>0">
              <td>{{item.feeTypeName}}</td>
              <td class="text-right">{{htmlToMoney(item.executedAmount)}}</td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6" style="text-align:right;font-size:22px;font-weight:bold">
              <a
                style="text-align:left;font-size:14px;font-weight:normal;padding-right:20px;"
                @click="handleEquityDetail"
              >查看明细</a>
              <span>总计：</span>
              <span style="color:red">{{htmlToMoney(feeBill.totalExecutedAmount)}}</span>
            </td>
          </tr>
        </tfoot>
      </table>
      <table class="dataTable m-t-10" border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td
              :rowspan="costBill.feeTypeList.length"
              class="dataTable-right-title"
              style="background-color:#2d8cf0;font-size:16px;"
            >成本额列表</td>
            <td>{{costBill.feeTypeList.length>0?costBill.feeTypeList[0].feeTypeName:''}}</td>
            <td
              class="text-right"
            >{{costBill.feeTypeList.length>0?htmlToMoney(costBill.feeTypeList[0].executedAmount):''}}</td>
          </tr>
          <template v-for="(item,index) in costBill.feeTypeList">
            <tr :key="'costBill_'+index" v-if="index>0">
              <td>{{item.feeTypeName}}</td>
              <td class="text-right">{{htmlToMoney(item.executedAmount)}}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3" style="text-align:right;font-size:22px;font-weight:bold">
              <a
                style="text-align:left;font-size:14px;font-weight:normal;padding-right:20px;"
                @click="handleCostDetail"
              >下载明细</a>
              <span>总计：</span>
              <span style="color:red">{{htmlToMoney(costBill.totalExecutedAmount)}}</span>
            </td>
          </tr>
        </tfoot>
      </table>
      <table class="dataTable m-t-10" border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td
              :rowspan="feeAdjustBill.feeTypeList.length"
              class="dataTable-right-title"
              style="background-color:#2d8cf0;font-size:16px;"
            >权益额调整项</td>
            <td>{{feeAdjustBill.feeTypeList.length>0?feeAdjustBill.feeTypeList[0].feeTypeName:''}}</td>
            <td
              class="text-right"
            >{{feeAdjustBill.feeTypeList.length>0?htmlToMoney(feeAdjustBill.feeTypeList[0].executedAmount):''}}</td>
          </tr>
          <template v-for="(item,index) in feeAdjustBill.feeTypeList">
            <tr :key="'costBill_'+index" v-if="index>0">
              <td>{{item.feeTypeName}}</td>
              <td class="text-right">{{htmlToMoney(item.executedAmount)}}</td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3" style="text-align:right;font-size:22px;font-weight:bold">
              <a
                style="text-align:left;font-size:14px;font-weight:normal;padding-right:20px;"
                @click="handleAdjustmentDetail"
              >查看明细</a>
              <span>总计：</span>
              <span style="color:red">{{htmlToMoney(feeAdjustBill.totalExecutedAmount)}}</span>
            </td>
          </tr>
        </tfoot>
      </table>
      <table class="dataTable m-t-10" border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <th
              rowspan="2"
              class="dataTable-right-title"
              style="background-color:#2d8cf0;font-size:16px;"
            >取费额</th>
            <th style="width:24%">权益发生总额</th>
            <th style="width:24%">成本总额</th>
            <th style="width:24%">调整项合计</th>
            <th style="width:26%">计算额（权益发生总额-成本总额+调整项）</th>
          </tr>
          <tr>
            <td class="text-center">{{htmlToMoney(partyAllocation.totalFeeAmount)}}</td>
            <td class="text-center">{{htmlToMoney(partyAllocation.totalCostAmount)}}</td>
            <td class="text-center">{{htmlToMoney(partyAllocation.totalFeeAdjustAmount)}}</td>
            <td class="text-center">{{htmlToMoney(partyAllocation.totalAmount)}}</td>
          </tr>
        </tbody>
      </table>
      <table class="dataTable m-t-10" border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <th
              rowspan="2"
              class="dataTable-right-title"
              style="background-color:#2d8cf0;font-size:16px;"
            >结算额</th>
            <th style="width:24%">当期计算额</th>
            <th style="width:24%">上期未结算额</th>
            <th style="width:24%">
              <span style="color:red">*</span>当期结算额
            </th>
            <th style="width:26%">未结算额</th>
          </tr>
          <tr>
            <td class="text-center">{{htmlToMoney(partyAllocation.totalAmount)}}</td>
            <td class="text-center">{{htmlToMoney(partyAllocation.historyUnsettlementAmount)}}</td>
            <td class="text-center">{{htmlToMoney(partyAllocation.realSettlementAmount)}}</td>
            <td class="text-center">{{htmlToMoney(partyAllocation.unSettlementAmount)}}</td>
          </tr>
        </tbody>
      </table>
      <table class="dataTable m-t-10" border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <th
              rowspan="3"
              class="dataTable-right-title"
              style="background-color:#2d8cf0;font-size:16px;"
            >收益</th>
            <th style="width:20%">收益对象</th>
            <th style="width:20%">当期结算额</th>
            <th style="width:20%">收益</th>
            <th style="width:20%">罚款额</th>
            <th style="width:20%">最终收益</th>
          </tr>
          <tr v-for="(item,index) in partyAllocation.partyAllocateBillList" :key="'party_'+index">
            <td class="text-center">{{item.desc}}</td>
            <td
              v-if="index===0"
              :rowspan="partyAllocation.partyAllocateBillList.length"
              class="text-center"
            >{{htmlToMoney(partyAllocation.realSettlementAmount)}}</td>
            <td
              class="text-center"
            >{{htmlToMoney(item.partyAllocateAmount)}} ({{item.partyAllocateDesc}})</td>
            <td class="text-center">
              <span v-if="item.desc.includes('业主')">/</span>
              <span v-else>
                {{htmlToMoney(item.partyAdjustAmount)}}&nbsp;
                <a href="javascript:void(0)" @click="handleViewFineDetail">查看明细</a>
              </span>
            </td>
            <td class="text-center">
              <span
                style="color:red;font-weight:bold"
              >{{htmlToMoney(item.partyFinalAllocateAmount)}}</span>
              ({{item.partyFinalAllocateDesc}})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal v-model="showFineDetail" width="1000">
      <p slot="header">查看罚款明细</p>
      <Table stripe :data="fineList" :columns="fineColumns"></Table>
      <div class="paging_style">
        <Page size="small"
          :current="fineQuery.pageNumber"
          :page-size="fineQuery.pageSize"
          :total="fineTotal"
          @on-change="fineChangePage"
          show-total
          show-elevator
        ></Page>
      </div>
    </Modal>
    <CostBill ref="costbillDetail"></CostBill>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getAgentBill } from '@/api/statement/agentbill'
import { updateBillStatus } from '@/api/statement/bill'
import { getPenaltyPage } from '@/api/statement/penalty'

import CostBill from '@/components/reconciliation/CostBill'

export default {
  components: {
    CostBill
  },
  data () {
    return {
      query: {},
      rejectReason: '', // 拒绝理由
      feeInfo: {},
      // basicFeeList: [], // 基础服务费
      incrementFeeList: [], // 增值服务费
      feeBillAmount: 0,
      feeBill: {
        feeTypeList: []
      },
      feeAdjustBill: {
        feeTypeList: []
      },
      costBill: {
        feeTypeList: []
      }, // 成本额
      partyAllocation: {
        partyAllocateBillList: []
      },
      contractList: [],
      showFineDetail: false,
      fineQuery: {
        feeType: 53,
        publisherId: 0,
        agentCompanyId: 0,
        keyword: '',
        maxPenaltyDate: '',
        minPenaltyDate: '',
        pageNumber: 1,
        pageSize: 15,
        status: '',
        supplierCompanyId: '',
        userId: null
      },
      fineList: [],
      fineTotal: 0,
      fineColumns: [
        {
          title: '罚款金额',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amount))
          }
        },
        { title: '罚款原因', align: 'center', key: 'content' },
        { title: '罚款日期', align: 'center', key: 'penaltyDate' },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人员', align: 'center', key: 'createUserName' }
      ]
    }
  },
  created () {
    this.initData()
    // this.getBillList()
  },
  methods: {
    initData () {
      const query = {
        companyId: this.bill.companyId,
        publisherId: this.bill.publisherId,
        startDate: this.bill.startDate,
        endDate: this.bill.endDate
      }
      getAgentBill(query).then((res) => {
        if (res && !res.errcode) {
          this.feeInfo = res
          this.feeBillAmount = res.feeBill.totalExecutedAmount
          this.feeBill = res.feeBill
          this.feeAdjustBill = res.feeAdjustBill
          this.costBill = res.costBill
          this.partyAllocation = res.partyAllocation
          if (
            res.feeBill.feetypeCategoryList &&
            res.feeBill.feetypeCategoryList.length > 0
          ) {
            this.basicFeeList = res.feeBill.feetypeCategoryList.find(
              (x) => x.feeTypeCategory === 100
            ).feeTypeList
            this.incrementFeeList = res.feeBill.feetypeCategoryList.find(
              (x) => x.feeTypeCategory === 101
            ).feeTypeList
          }
        } else {
          this.$Notice.error({ title: '获取费用详情出错', desc: res.errmsg })
        }
      })
    },
    handleAdjustment () {
      this.$router.push({
        name: 'agent-statement-adjustment',
        params: { id: 1 }
      })
    },
    /**
     * 确认发起对账
     */
    handleLaunch () {
      if (this.serviceType === 1) {
        this.$Modal.confirm({
          title: '操作提示',
          content: '确定要发起对账',
          onOk: () => {
            this.handleSubmit(3)
          }
        })
      } else {
        this.$Modal.confirm({
          title: '操作提示',
          content: '确认该对账无误？',
          onOk: () => {
            this.handleSubmit(4)
          }
        })
      }
    },
    /**
     * 撤回发起对账
     */
    handleRecall () {
      let noticeTitle = ''
      let placeholder = ''
      if (this.serviceType === 1) {
        noticeTitle = '填写撤回理由'
        placeholder = '撤回理由'
      } else {
        noticeTitle = '填写拒绝理由'
        placeholder = '拒绝理由'
      }
      this.$Modal.confirm({
        title: noticeTitle,
        render: (h) => {
          return h('Input', {
            props: {
              value: this.rejectReason,
              autofocus: true,
              placeholder: placeholder
            },
            on: {
              input: (val) => {
                this.rejectReason = val
              }
            }
          })
        },
        onOk: () => {
          this.handleSubmit(2)
        }
      })
    },
    handleSubmit (status) {
      const postData = {
        billId: this.feeInfo.id,
        status: status,
        mark: this.rejectReason
      }
      updateBillStatus(postData).then((res) => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.rejectReason = ''
          this.initData()
          this.getBillList()
        } else {
          this.$Notice.error({ title: '操作过程出错', desc: res.errmsg })
        }
      })
    },
    htmlToMoney (obj) {
      return toMoney(obj)
    },
    /**
     * 查看权益额明细
     */
    handleEquityDetail () {
      this.$store.commit('set_reconciliation_pageType', 'equityAmountDetail')
    },
    /**
     * 查看成本详情
     */
    // handleCostDetail () {
    //   const routerName =
    //     this.serviceType === 1
    //       ? 'owner-agent-statement-cost-detail'
    //       : 'agent-statement-cost-detail'
    //   this.$router.push({
    //     name: routerName,
    //     params: {
    //       companyId: this.query.companyId,
    //       startDate: this.query.startDate,
    //       endDate: this.query.endDate
    //     }
    //   })
    // },
    /**
     * 查看成本详情
     */
    handleCostDetail () {
      this.$refs.costbillDetail.showModal()
      // this.fineQuery.pageNumber = 1
      // this.getPenaltyData()
    },
    /**
     * 查看调整项明细
     */
    handleAdjustmentDetail () {
      this.$store.commit(
        'set_reconciliation_pageType',
        'equityAdjustmentDetail'
      )
    },
    /**
     * 查看罚款明细
     */
    handleViewFineDetail () {
      this.showFineDetail = true
      this.fineQuery.pageNumber = 1
      this.getPenaltyData()
    },
    /**
     * 获取代理商罚款明细
     */
    getPenaltyData () {
      this.fineQuery.agentCompanyId = this.bill.companyId
      this.fineQuery.publisherId = this.bill.publisherId
      this.fineQuery.minPenaltyDate = this.bill.startDate
      this.fineQuery.maxPenaltyDate = this.bill.endDate
      getPenaltyPage(this.fineQuery).then((res) => {
        if (res && !res.errcode) {
          this.fineList = res.list
          this.fineTotal = res.totalRow
          this.fineQuery.pageNumber = res.pageNumber
        } else {
          this.fineList = []
          this.fineTotal = 0
          this.fineQuery.pageNumber = 1
        }
      })
    },
    fineChangePage (curPage) {
      this.fineQuery.pageNumber = curPage
      this.getPenaltyData()
    }
  },
  computed: {
    bill () {
      return this.$store.state.reconciliation.bill
    },
    serviceType () {
      return this.$store.state.reconciliation.serviceType
    }
  }
}
</script>
