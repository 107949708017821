import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询代理商供应商某段时间交易汇总
 * @param {Object} data
 */
export function getAgentBillList (data) {
  return request({
    url: '/ooh-statement/agentsupplierbill/getagentsupplierbill',
    method: 'post',
    data: qs.stringify(data)
  })
}
