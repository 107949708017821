import request from '@/utils/requestV2'
const qs = require('qs')

// 删除罚单
export function deletePenalty (data) {
  return request({
    url: '/ooh-statement/v1/penalty/deletepenalty',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取罚单详情
export function getPenalty (data) {
  return request({
    url: '/ooh-statement/v1/penalty/getpenalty',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取罚单列表（分页）
export function getPenaltyPage (data) {
  return request({
    url: '/ooh-statement/v1/penalty/getpenaltypage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 作废罚单
export function invalidPenalty (data) {
  return request({
    url: '/ooh-statement/v1/penalty/invalidpenalty',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 撤回审批中的罚单
export function terminatePenaltyApproval (data) {
  return request({
    url: '/ooh-statement/v1/penalty/terminatepenaltyapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
